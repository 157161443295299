import React from "react";

import Button from "./ButtonWrapper";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";

import SupportIcon from "./icons/SupportIcon";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

const styles = {
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: "#00abec",
        textAlign: "center",
        "& path, & circle": {
            stroke: "#fff"
        }
    },
    titleContainer2: {
        backgroundColor: "#00abec",
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: "#00abec",
        fontWeight: 500
    },
    nooverflow: {
        overflow: "hidden"
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    }
};

const HelpDialog = ({
    question,
    text,
    classes,
    confirmAction,
    cancelAction,
    confirmText,
    cancelText,
    onClose,
    showErrorOutline,
    ...other
}) => (
    <Dialog onClose={onClose} {...other} classes={{ paper: classes.nooverflow }}>
        <DialogTitle className={showErrorOutline ? classes.titleContainer2 : classes.titleContainer}>
            {showErrorOutline && <ErrorOutline className={classes.supportIcon} />}
            {!showErrorOutline && <SupportIcon className={classes.supportIcon} />}
            <IconButton onClick={onClose} className={classes.closeIcon} size="large">
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography variant="title" className={classes.questionTitle} gutterBottom>
                {question}
            </Typography>
            <DialogContentText>{text}</DialogContentText>
            {confirmAction && (
                <DialogActions className={classes.centerButtons}>
                    <Button onClick={confirmAction} color="secondary" variant="raised">
                        {confirmText ? confirmText : "Ok"}
                    </Button>
                    <Button onClick={cancelAction} variant="raised" className={classes.greyButton} autoFocus>
                        {cancelText ? cancelText : "Cancel"}
                    </Button>
                </DialogActions>
            )}
            {!confirmAction && (
                <DialogActions className={classes.centerButtons}>
                    <Button onClick={onClose} color="secondary" variant="raised">
                        Ok
                    </Button>
                </DialogActions>
            )}
        </DialogContent>
    </Dialog>
);

export default withStyles(styles)(HelpDialog);
