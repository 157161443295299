import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography } from "@mui/material";

// Custom CSS classes
const styles = theme => ({
    footer: {
        width: "7.5in",
        position: "absolute",
        bottom: 0,
        left: 0,
        paddingBottom: ".5em",
        paddingTop: ".5em",
        paddingLeft: ".5in",
        paddingRight: ".5in",
        color: "rgba(0, 0, 0, 0.54)",
        backgroundColor: "#D3D3D3"
    },
    text: {
        color: "black",
        fontSize: 13,
        textAlign: "center",
        fontWeight: "bold"
    }
});

class PageFooter extends Component {
    render() {
        const { classes, number, total } = this.props;

        return (
            <div className={classes.footer}>
                <table style={{ width: "100%", margin: -4 }}>
                    <tr>
                        <td>
                            <Typography className={classes.text}>{new Date().toLocaleDateString()}</Typography>
                        </td>
                        <td>
                            <Typography className={classes.text}>
                                Field to Market: The Alliance for Sustainable Agriculture
                            </Typography>
                        </td>
                        <td>
                            <Typography className={classes.text}>
                                Page {number} of {total}
                            </Typography>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default withStyles(styles)(PageFooter);
