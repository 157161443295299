import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class RotationSystem extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "RotationSystem";
    }
    static generateCode(payload, state) {
        const session = orm.session(state.orm);

        let template = (payload && payload.template) || "custom",
            nextCode = `${template}`,
            suffix = 0;
        while (nextCode === "custom" || session.RotationSystem.hasId(nextCode)) {
            suffix++;
            nextCode = `${template}_${suffix}`;
        }
        return nextCode;
    }
    toRotationJSON() {
        var json = {};
        json.events = this.events.toModelArray().map(event => event.toRotationJSON());
        return json;
    }
}
orm.register(RotationSystem);

export class RotationEvent extends Model {
    static get fields() {
        return {
            system: fk("RotationSystem", "events")
        };
    }
    static get modelName() {
        return "RotationEvent";
    }
    static get codeWidth() {
        return 2;
    }
    toRotationJSON() {
        const json = { ...this.ref };
        delete json.id;
        delete json.code;
        delete json.system;
        delete json.modify_date;
        delete json.modify_user;
        return json;
    }
}
orm.register(RotationEvent);

export class ProjectRotationSystem extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "ProjectRotationSystem";
    }
}
orm.register(ProjectRotationSystem);
