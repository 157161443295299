import React from "react";
import { Link } from "react-router-dom";
import Button from "./ButtonWrapper";
import Grid from "./GridWrapper";
import ActionButton from "./ActionButton";

export default function Submit({ label, altAction, altLabel, loading }) {
    return (
        <Grid container justifyContent="center">
            {altAction ? (
                <Grid item xs={6}>
                    <Button fullWidth component={Link} to={altAction}>
                        {altLabel}
                    </Button>
                </Grid>
            ) : (
                <div />
            )}
            <Grid item xs={6}>
                <ActionButton type="submit" loading={loading} label={label} />
            </Grid>
        </Grid>
    );
}
