import React from "react";

const DetailedResults = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 39.85 49.11">
        <path fill="#666" d="M28.15 6.5v10.35H38.5v31.26h-32V6.5h21.65m1-1H5.5v43.61h34V15.85H29.15V5.5z" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M24.15 0.5L0.5 0.5 0.5 44.11" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M29.89 5.5L39.5 15.11" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M10.67 26.2L24.72 26.2" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M10.67 31.37L34.33 31.37" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M10.67 36.54L34.33 36.54" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M10.67 41.72L34.33 41.72" />
    </svg>
);

export default DetailedResults;
