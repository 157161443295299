import React, { Component } from "react";
import { FormField } from "react-form";
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";
import { FormLabel, FormControl, FormControlLabel, FormHelperText, FormGroup } from "@mui/material";
import HelpLabel from "./HelpLabel";

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
});

class CheckboxGroupWrapper extends Component {
    state = {
        disabled: []
    };

    handleChange = event => {
        const { fieldApi, eventHandle, mutex } = this.props;
        const { getValue, setValue } = fieldApi;

        const value = getValue() || [];

        if (event.target.checked) {
            value.push(event.target.value);
            if (mutex && mutex.includes(event.target.value)) {
                mutex
                    .filter(val => val !== event.target.value)
                    .forEach(val => {
                        var index = value.indexOf(val);
                        if (index > -1) {
                            value.splice(index, 1);
                        }
                    });
            }
        } else {
            var index = value.indexOf(event.target.value);
            if (index > -1) {
                value.splice(index, 1);
            }
        }

        setValue(value);

        if (eventHandle) eventHandle(value, this);

        // The add crop year checkboxes were not being re-rendered when unchecked for some unknown reason
        this.setState({ foo: "bar" });
    };

    render() {
        const { fieldApi, label, help, options, classes, required, helperText, fullWidth, row, ...rest } = this.props;

        const { getValue, getError } = fieldApi;

        const value = getValue() || [],
            error = getError();

        return (
            <FormControl
                component="fieldset"
                required={required}
                error={error}
                fullWidth={fullWidth}
                className={classes.formControl}>
                <FormLabel component="legend">
                    {help ? <HelpLabel inputLabel={label} helpText={help} /> : label}
                </FormLabel>
                <FormGroup row={row}>
                    {options.map(object => (
                        <FormControlLabel
                            key={object.value}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={value.includes(object.value)}
                                    onChange={this.handleChange}
                                    value={object.value}
                                    disabled={this.state.disabled[object.value]}
                                    {...rest}
                                />
                            }
                            label={object.label}
                        />
                    ))}
                </FormGroup>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppCheckboxGroup = FormField(CheckboxGroupWrapper);

export default withStyles(styles)(FppCheckboxGroup);
