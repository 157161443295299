import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "../../common/TypographyWrapper";

// Custom CSS classes
const styles = theme => ({
    footer: {
        width: "7.5in",
        position: "absolute",
        bottom: 0,
        left: 0,
        paddingBottom: "1.5em",
        paddingLeft: "0.5in",
        paddingRight: "0.5in"
    },
    horizontalView: {
        display: "flex"
    },
    spacer: {
        flex: 1
    }
});

class PageFooter extends Component {
    render() {
        const { classes, page, pages } = this.props,
            year = new Date().getFullYear();

        return (
            <div className={classes.footer}>
                <div className={classes.horizontalView}>
                    <Typography variant={"caption"}>
                        Copyright &copy; {year} Field to Market. All Rights Reserved.
                    </Typography>
                    <div className={classes.spacer} />
                    <Typography variant={"caption"}>
                        Page {page} of {pages}
                    </Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(PageFooter);
