import React, { Component } from "react";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Snackbar, SnackbarContent } from "@mui/material";

const styles = theme => ({
    snackbar: {
        marginBottom: 40
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarContainerSuccess: {
        backgroundColor: "#43a047"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    }
});

class SnackbarWrapper extends Component {
    constructor(props) {
        super(props);
        if (props.auto) {
            this.state = { open: true };
        }
    }
    render() {
        const { classes, auto, error, success, count, section, action = "saved" } = this.props;

        let open, handleClose;
        if (auto) {
            open = this.state.open;
            handleClose = () => this.setState({ open: false });
        } else {
            open = this.props.open;
            handleClose = this.props.onClose;
        }

        let message;
        if (error) {
            if (error === true) {
                const fields = count > 1 ? "fields" : "field";
                message = `You are missing ${count} required ${fields} in this section.`;
            } else {
                message = error;
            }
        } else if (success) {
            if (success === true) {
                message = `You have successfully ${action} this ${section}.`;
            } else {
                message = success;
            }
        } else {
            throw new Error("Missing success or error");
        }

        return success ? (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                className={classes.snackbar}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <SnackbarContent
                    className={classes.snackbarContainerSuccess}
                    message={
                        <span className={classes.snackbarMessage}>
                            <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                            {message}
                        </span>
                    }
                />
            </Snackbar>
        ) : (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                className={classes.snackbar}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}>
                <SnackbarContent
                    className={classes.snackbarContainer}
                    message={
                        <span className={classes.snackbarMessage}>
                            <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                            {message}
                        </span>
                    }
                />
            </Snackbar>
        );
    }
}

export default withStyles(styles)(SnackbarWrapper);
