import React, { Component } from "react";
import { connect } from "react-redux";

import { createSelector } from "../../common/orm";
import PreviewRotation from "../../rotation/PreviewRotation";

const getRotationSystem = createSelector(
    (state, ownProps) => ownProps.selectedRotation,
    (session, systemId) => {
        const system = session.RotationSystem.withId(systemId);
        return (
            system && {
                events: system.events.toRefArray(),
                ...system.ref
            }
        );
    }
);

// This component exists just so we can use selectedRotation as a prop (in getEvents)
class RotationWrapper extends Component {
    render() {
        const { rotationPreviewOpen, system, onPreviewCancelClick } = this.props;

        return (
            <PreviewRotation
                open={rotationPreviewOpen}
                rotationSystem={system}
                onPreviewCancelClick={onPreviewCancelClick}
                showCopy={false}
            />
        );
    }
}

RotationWrapper = connect((state, ownProps) => ({
    system: getRotationSystem(state, ownProps)
}))(RotationWrapper);

export default RotationWrapper;
