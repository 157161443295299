import React from "react";

const PrintableReport = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 34.35 43.96">
        <path fill="#666" d="M22.65 1.35v10.38H33v31.26H1V1.35h21.65m1-1H0v43.61h34V10.73H23.65V.35z" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M24.39 0.35L34 9.96" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M5.17 21.05L19.22 21.05" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M5.17 26.22L28.83 26.22" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M5.17 31.4L28.83 31.4" />
        <path fill="none" stroke="#666" strokeMiterlimit="10" d="M5.17 36.57L28.83 36.57" />
        <path
            fill="#666"
            d="M6.39 11.73v2.43h-.17v-2.43h.22m1-1H5.22v4.43h2.22v-4.43zM10.09 9.48v4.65h-.22V9.48h.22m1-1H8.87v6.65h2.22V8.48zM13.78 7.26v6.87h-.21V7.26h.21m1-1h-2.21v8.87h2.21V6.26z"
        />
    </svg>
);

export default PrintableReport;
