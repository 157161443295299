import React from "react";

const CometPlanner = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 34.15 26.69">
        <polygon
            points="33.15 16.77 17.07 26.12 0.99 16.77 17.07 7.42 33.15 16.77"
            fill="none"
            stroke="#b284ff"
            strokeMiterlimit="10"
        />
        <polygon
            points="33.15 9.93 17.07 19.27 0.99 9.93 17.07 0.58 33.15 9.93"
            fill="none"
            stroke="#b284ff"
            strokeMiterlimit="10"
        />
    </svg>
);

export default CometPlanner;
