import React, { Component } from "react";
import { RotationEvent } from "./models";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";

const getEvents = createSelector(
    (state, ownProps) => ownProps.rotationSystemId,
    (session, systemId) =>
        session.RotationEvent.filter({
            system: systemId
        }).toRefArray()
);

class ExportRotationSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.exportSystem(this.props.rotationSystemId, this.props.events);
    }

    render() {
        return <div />;
    }
}

ExportRotationSystem = connect(
    (state, ownProps) => ({
        events: getEvents(state, ownProps)
    }),
    RotationEvent.actions
)(ExportRotationSystem);

export default ExportRotationSystem;
