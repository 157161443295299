import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";

const styles = theme => ({
    display1: {
        fontSize: "36px",
        fontWeight: 100,
        color: "#666666"
    },
    //Username
    display2: {
        fontSize: "18px",
        fontWeight: 400,
        color: "#666666"
    },
    //Form group headings
    display3: {
        borderBottom: "2px solid #efefef",
        fontSize: "30px",
        fontWeight: 400,
        marginLeft: 0,
        letterSpacing: 0
    },
    //Form group heading no underline
    display4: {
        fontSize: "30px",
        fontWeight: 400,
        marginLeft: 0,
        letterSpacing: 0
    },
    // Smaller headings
    title: {
        fontWeight: 400,
        fontSize: "20px",
        color: "#666666"
    },
    // Normal text
    body1: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#666666"
    },
    caption: {
        fontSize: 11
    },
    headline: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1.5rem",
        fontWeight: 400,
        fontFamily: "'Source Sans Pro', sans-serif",
        lineHeight: "1.35417em"
    }
});

const variantMap = {
    display4: "h1",
    display3: "h1",
    display2: "h1",
    display1: "h1",
    headline: "h1",
    title: "h2",
    subheading: "subtitle1",
    body2: "body1",
    body1: "body1"
};

// See https://react-form.js.org/#/custom-input
class TypographyWrapper extends Component {
    render() {
        const { classes, variant, className, forwardedRef, ...rest } = this.props;

        var newVariant = "body1";

        if (variant !== null && variant !== undefined) {
            newVariant = variantMap[variant];
        }

        var c = null;
        if (className) {
            c = className;
        } else if (variant && variant in classes) {
            c = classes[variant];
        }

        return <Typography className={c} variant={newVariant} ref={forwardedRef} {...rest} />;
    }
}

TypographyWrapper = withStyles(styles)(TypographyWrapper);

export default React.forwardRef((props, ref) => <TypographyWrapper {...props} forwardedRef={ref} />);
