import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import apikey from "../../apikey.json";

const pending = {};

export default function Geocoder({ onSetBounds }) {
    const [addressOptions, setAddressOptions] = useState([]),
        [addressLoading, setAddressLoading] = useState(false),
        [addressNoOptionsText, setAddressNoOptionsText] = useState("Start typing an address."),
        handleAddress = async (evt, address, reason) => {
            if (reason === "input") {
                if (address.length <= 4) {
                    setAddressNoOptionsText("Continue typing...");
                    return;
                }

                try {
                    setAddressLoading(true);
                    const choices = await geocode(address);
                    setAddressOptions(choices);
                    setAddressLoading(false);
                    if (choices.length === 0) {
                        setAddressNoOptionsText("No results found.");
                    }
                } catch (e) {
                    setAddressLoading(false);
                    if (e.name !== "AbortError") {
                        setAddressNoOptionsText("Error searching for address.");
                    }
                }
            } else if (reason === "reset") {
                const option = addressOptions.find(option => option.label === address);
                if (option && option.bounds) {
                    onSetBounds(option.bounds);
                }
            }
        };
    return (
        <Autocomplete
            loading={addressLoading}
            options={addressOptions.map(option => option.label)}
            filterOptions={options => options}
            noOptionsText={addressNoOptionsText}
            onInputChange={handleAddress}
            renderInput={renderInput}
        />
    );
}

function renderInput(props) {
    const handleChange = props.inputProps.onChange;
    props = {
        ...props,
        inputProps: {
            ...props.inputProps,
            onChange(evt) {
                evt.preventDefault();
                handleChange(evt);
            }
        }
    };
    return <TextField {...props} label="Address or Place name" />;
}

async function geocode(address) {
    const controller = new AbortController(),
        url = new URL("https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates");
    url.searchParams.append("singleLine", address);
    url.searchParams.append("f", "json");
    url.searchParams.append("token", apikey.agolToken);
    if (pending.current) {
        pending.current.abort();
    }
    pending.current = controller;
    const response = await fetch(url, { signal: controller.signal }),
        data = await response.json();
    pending.current = null;
    const seen = {};
    return data.candidates.map(candidate => {
        const { xmin, xmax, ymin, ymax } = candidate.extent;
        let i = 1,
            label = candidate.address;
        while (seen[label]) {
            i++;
            label = `${candidate.address} (${i})`;
        }
        seen[label] = true;
        return {
            label,
            bounds: [
                [xmin, ymin],
                [xmax, ymax]
            ]
        };
    });
}
