import React from "react";

const DocumentIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 26.2195 32.9999">
        <polyline
            points="25.72 9.667 25.72 32.5 0.5 32.5 0.5 0.5 16.943 0.5"
            fill="none"
            stroke="#cacaca"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <polygon
            points="25.61 9.5 16.61 9.5 16.61 0.5 25.61 9.5"
            fill="none"
            stroke="#cacaca"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <line x1="5.6098" y1="11" x2="12.6098" y2="11" fill="none" stroke="#cacaca" stroke-miterlimit="10" />
        <line x1="5.6098" y1="15" x2="20.6098" y2="15" fill="none" stroke="#cacaca" stroke-miterlimit="10" />
        <line x1="5.6098" y1="19" x2="20.6098" y2="19" fill="none" stroke="#cacaca" stroke-miterlimit="10" />
        <line x1="5.6098" y1="23" x2="20.6098" y2="23" fill="none" stroke="#cacaca" stroke-miterlimit="10" />
    </svg>
);

export default DocumentIcon;
