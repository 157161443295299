import React from "react";

const MagnifyIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 64 68">
        <g transform="translate(0.000000,68.000000) scale(0.100000,-0.100000)" fill="#ccc" stroke="none">
            <path
                d="M0 335 l0 -335 320 0 320 0 0 335 0 335 -320 0 -320 0 0 -335z m367
            158 c72 -34 106 -124 78 -207 l-17 -49 41 -41 c44 -45 50 -63 25 -73 -10 -4
            -31 9 -58 37 l-43 42 -49 -18 c-114 -40 -225 35 -226 154 0 70 33 125 97 160
            30 17 112 14 152 -5z"
            />
            <path
                d="M231 452 c-38 -21 -71 -73 -71 -113 0 -33 38 -90 70 -106 76 -39 168
            12 177 97 5 56 -22 105 -72 126 -45 18 -63 18 -104 -4z"
            />
        </g>
    </svg>
);

export default MagnifyIcon;
