import React from "react";

const CornerArrow = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 21.91 21.65">
        <path fill="none" stroke="#fff" d="M.5,0V21.15H21.91" />
        <path fill="none" stroke="#fff" d="M16.78,16V5.13h-11" />
        <line fill="none" stroke="#fff" x1="5.63" y1="16.3" x2="16.68" y2="5.25" />
    </svg>
);

export default CornerArrow;
