import React from "react";

const StepTwoIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 148 144">
        <circle cx="76" cy="72" r="72" fill="#f2f2f2" />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M57.7 49.52h6.38a12.77 12.77 0 0112.77 12.77v0h0-6.38A12.77 12.77 0 0157.7 49.52v0h0zM83.23 75.05h-6.38 0a12.77 12.77 0 0112.78-12.76h6.38a12.77 12.77 0 01-12.78 12.76zM57.7 71.23h6.38A12.77 12.77 0 0176.85 84v0h0-6.38A12.77 12.77 0 0157.7 71.23v0h0z"
        />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M76.85 98.48L76.85 62.29" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M65.5 98.48L87.7 98.48" />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M126.5 72a50.47 50.47 0 11-8.52-28.09q1.4 2.09 2.59 4.31c.71 1.34 1.37 2.72 2 4.13a46.89 46.89 0 011.65 4.52 47.39 47.39 0 011.25 4.79"
        />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M118.8 57.62L125.5 61.5 128.5 54.5" />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M121 72a45 45 0 11-45-45c.72 0 1.44 0 2.15.05s1.62.09 2.42.18c1 .1 2 .24 3 .41s1.8.33 2.69.54c1.1.26 2.19.56 3.26.89a43.64 43.64 0 014.18 1.56q1.82.78 3.54 1.71c1.23.66 2.43 1.38 3.59 2.15s2 1.39 3 2.15c.76.6 1.51 1.23 2.23 1.88s1.61 1.5 2.38 2.3c.54.56 1.07 1.15 1.59 1.74s1.21 1.45 1.79 2.2 1.07 1.46 1.58 2.21a43.78 43.78 0 012.3 3.84"
        />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M115 72a39 39 0 11-39-39 40.18 40.18 0 014.41.24c1.18.14 2.35.32 3.5.56a37.75 37.75 0 013.66.94c.83.26 1.65.54 2.45.85a39.82 39.82 0 014 1.8 39.36 39.36 0 015.13 3.21 39.88 39.88 0 015.93 5.39"
        />
        <circle cx="18.5" cy="19.5" r="18.5" fill="#a368ff" />
        <path
            fill="#fff"
            d="M13.95 25.79c4.39-4.24 7.14-7 7.14-9.42a2.44 2.44 0 00-2.6-2.73 4.34 4.34 0 00-3.07 1.76l-1.59-1.58a6.44 6.44 0 015-2.43 4.61 4.61 0 015 4.83c0 2.8-2.61 5.68-5.73 9 .82-.08 1.86-.18 2.63-.18h3.86v2.43H13.95z"
        />
    </svg>
);

export default StepTwoIcon;
