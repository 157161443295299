import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Grid from "../common/GridWrapper";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";
import { dbFetch } from "../../api/fetch";

const styles = theme => ({
    flex: {
        flex: 1
    },
    contentPadding: {
        padding: "24px"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    searchButton: {
        border: "1px solid #979797"
    }
});

function ImportFieldDialog({ open, onClose: handleClose, onSave: handleSave, classes }) {
    const [selectedFile, setSelectedFile] = useState(""),
        [geometry, setGeometry] = useState(null),
        [message, setMessage] = useState(null);

    const resetState = () => {
            setSelectedFile("");
            setGeometry(null);
            setMessage(null);
        },
        handleSaveClick = () => {
            if (geometry) {
                handleSave(geometry);
            }
            resetState();
        },
        handleCancelClick = () => {
            resetState();
            handleClose();
        };

    async function loadGeometryFile(evt) {
        const input = evt.target,
            file = input.files[0];

        setSelectedFile(file.name);
        setMessage("Loading file...");

        try {
            const data = await loadFile(file);
            setGeometry(data);
            setMessage("Geometry ready to apply.");
        } catch (err) {
            setMessage(err.message || "Error loading file");
            setSelectedFile(null);
        }
    }
    const saveDisabled = !geometry;

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <Toolbar>
                <Typography variant="title" className={classes.flex}>
                    Import Field Boundary File
                </Typography>
                <Tooltip title="Close">
                    <IconButton onClick={handleCancelClick} size="large">
                        <Close color="primary" />
                    </IconButton>
                </Tooltip>
            </Toolbar>

            <DialogContent className={classes.contentPadding}>
                <Typography variant="body1" gutterBottom>
                    You can import a single field boundary using two formats: Shapefile or GeoJSON. Note that the import
                    function will import a single boundary. If your upload includes multiple fields, only one will be
                    selected and it may or may not be the field needed. Therefore, please make sure your file includes a
                    single field boundary.
                </Typography>
                <Typography variant="subheading2">Shapefile Import</Typography>
                <Typography variant="body1" gutterBottom>
                    The shapefile must be uploaded in a .zip format (less than 100 MB) and include at least the
                    mandatory shapefile components (.shp, .shx, .dbf). You should include the projection file (.prj) to
                    ensure the coordinate system is defined.
                </Typography>
                <Typography variant="subheading2">GeoJSON Import</Typography>
                <Typography variant="body1" gutterBottom>
                    GeoJSON files should be uploaded with a .json or .geojson file extension.
                </Typography>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <input
                            accept=".geojson,.json,.zip"
                            className={classes.input}
                            id="raised-button-file"
                            type="file"
                            hidden
                            onChange={loadGeometryFile}
                        />
                        <label htmlFor="raised-button-file">
                            <Button raised component="span" className={classes.searchButton} fullWidth>
                                {selectedFile ? (
                                    selectedFile
                                ) : (
                                    <>
                                        <SearchIcon className={classes.optionButtonIcon} />
                                        Search for file
                                    </>
                                )}
                            </Button>
                        </label>
                    </Grid>

                    {message && (
                        <Grid item xs={12}>
                            <Typography>{message}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleCancelClick}
                    color="primary"
                    className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                    Cancel
                </Button>

                <Button
                    disabled={saveDisabled}
                    onClick={handleSaveClick}
                    className={classes.buttonMargin}
                    variant="raised"
                    color="primary">
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles)(ImportFieldDialog);

async function loadFile(file) {
    if (file.name.match(/json$/)) {
        return await loadGeojson(file);
    } else if (file.name.match(/zip$/)) {
        return await loadShapefile(file);
    } else {
        throw new Error("Unrecognized file type");
    }
}

async function loadGeojson(file) {
    const result = await new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsText(file);
    });

    let geojson;
    try {
        geojson = JSON.parse(result);
    } catch (err) {
        if (err instanceof SyntaxError) {
            throw new Error(
                "ERROR: Could not parse the GeoJSON file due to syntax error.  Please review the rotation file. " +
                    err.message
            );
        } else {
            throw new Error("Error: Could not parse the GeoJSON file. " + err.message);
        }
    }

    return getGeometry(geojson);
}

async function loadShapefile(file) {
    const body = new FormData();
    body.append("file", file);
    const response = await dbFetch("/api/shp2geojson", {
        method: "POST",
        body,
        headers: {
            "content-type": "multipart/form-data"
        }
    });

    const data = await response.json();

    if (data.error) {
        throw new Error("Shapefile conversion error: " + data.error);
    }

    return getGeometry(data);
}

function getGeometry(geojson) {
    if (geojson.type === "FeatureCollection") {
        geojson = (geojson.features || [])[0];
        if (!geojson) {
            throw new Error("Empty FeatureCollection");
        }
    }
    if (geojson.type === "Feature") {
        geojson = geojson.geometry;
        if (!geojson) {
            throw new Error("Feature missing geometry");
        }
    }
    if (geojson.type !== "Polygon" && geojson.type !== "MultiPolygon") {
        throw new Error("Unexpected geometry type: " + geojson.type);
    }
    return geojson;
}
