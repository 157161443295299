import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import { FormField } from "react-form";
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";
import Grid from "./GridWrapper";
import Tooltip from "@mui/material/Tooltip";
import { FormLabel, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: "100%"
    },
    grid: {
        marginLeft: 0
    },
    marginBottom: {
        marginBottom: theme.spacing(1)
    },
    tooltip: {
        width: 300
    },
    hover: {
        width: "100%",
        "&:hover": {
            backgroundColor: "rgb(95 198 255 / 50%)"
        }
    }
});

// Specialized CheckboxGroup with search/scroll
class CheckboxGroupWrapper extends Component {
    state = {
        filterString: ""
    };

    handleChange = event => {
        const { fieldApi, eventHandle } = this.props;
        const { getValue, setValue } = fieldApi;

        const value = getValue() || [];

        if (event) {
            if (event.target.checked) value.push(event.target.value);
            else {
                var index = value.indexOf(event.target.value);
                if (index > -1) {
                    value.splice(index, 1);
                }
            }
            setValue(value);
            this.setState({ value }); // Trigger re-render (FIXME: use hook)
        }

        if (eventHandle) eventHandle(value, this);
    };

    updateFilterString = event => {
        this.setState({ filterString: event.target.value.toLowerCase() });
    };

    render() {
        const { fieldApi, label, practices, classes, required, row, ...rest } = this.props;

        const { filterString } = this.state;

        const { getValue, getError } = fieldApi;

        const value = getValue() || [],
            error = getError();

        const options = practices
            .map(practice => ({
                value: practice.id,
                label: practice.name,
                title: `${practice.description} (NRCS Code ${practice.nrcs_id})`,
                search: `${practice.name} ${practice.description} ${practice.nrcs_id}`.toLowerCase(),
                checked: value.includes(practice.id)
            }))
            .filter(practice => practice.checked || !filterString || practice.search.includes(filterString))
            .sort((a, b) => {
                if (a.checked === b.checked) {
                    if (a.label > b.label) {
                        return 1;
                    } else if (a.label < b.label) {
                        return -1;
                    } else {
                        return 0;
                    }
                } else if (a.checked) {
                    return -1;
                } else {
                    // b.checked
                    return 1;
                }
            });

        return (
            <FormControl component="fieldset" required={required} error={error} className={classes.formControl}>
                <FormLabel component="legend" className={classes.marginBottom}>
                    {label}
                </FormLabel>
                <TextField
                    id="practice-search"
                    label="Search Filter"
                    placeholder="Start typing to search for a practice."
                    type="search"
                    margin="normal"
                    variant="filled"
                    onChange={e => this.updateFilterString(e)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                />
                <div style={{ "max-height": "250px", "min-height": "250px", "overflow-y": "auto" }}>
                    <Grid container>
                        {options.map(object => (
                            <Grid xs={12}>
                                <Tooltip
                                    key={object.value}
                                    title={object.title}
                                    classes={{
                                        tooltip: classes.tooltip
                                    }}>
                                    <FormControlLabel
                                        className={classes.hover}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                style={{ height: 30 }}
                                                checked={object.checked}
                                                onChange={this.handleChange}
                                                value={object.value}
                                                {...rest}
                                            />
                                        }
                                        label={object.label}
                                        fullWidth
                                    />
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppCheckboxGroup = FormField(CheckboxGroupWrapper);

export default withStyles(styles)(FppCheckboxGroup);
