import React, { Component } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Grid from "../../common/GridWrapper";
import SpiderGram from "./SpiderGram";
import SpiderGramTable from "./SpiderGramTable";
import MetricDetail from "./MetricDetail";
import InputDetail, { getPageRows } from "./InputDetail";
import PageHeader from "./inputs/PageHeader";
import PageFooter from "./inputs/PageFooter";
import { Header, Paragraph, Caption } from "./Table";

import { getValue } from "../../../api/utils";
import { getMetrics, getBreakBefore, getBreakAfter } from "./StaticData";

// Custom CSS classes
const styles = theme => ({
    root: {
        padding: 8,
        fontSize: 11
    },
    spiderGramPadding: {
        paddingTop: 10
    },
    page: {
        height: "11in",
        width: "8.5in",
        overflow: "hidden",
        position: "relative",
        pageBreakAfter: "always"
    },
    content: {
        width: "7.5in",
        paddingLeft: "0.5in",
        paddingRight: "0.5in"
    },
    column: {
        position: "absolute",
        top: "1.4in",
        width: "3.625in",
        height: "9in",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start"
    },
    column1: {
        left: "0.5in"
    },
    column2: {
        right: "0.5in"
    },
    metric: {
        marginBottom: 24
    },
    "@media screen": {
        page: {
            border: "1px solid #ccc",
            marginTop: "1em"
        }
    }
});

const themeOverrides = {
    MuiTypography: {
        title: {
            fontSize: 15
        },
        body2: {
            fontSize: 13,
            lineHeight: "1.4em"
        }
    },
    MuiTableCell: {
        body: {
            fontSize: "13px !important"
        },
        head: {
            fontSize: "13px !important"
        }
    }
};

class SummarySection extends Component {
    render() {
        const { classes, cropYear, pageCount } = this.props;

        return (
            <>
                <div className={classes.page}>
                    <PageHeader first cropYear={cropYear} />

                    <div className={classNames(classes.content, classes.firstPageContent)}>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Header style={{ color: "rgba(0, 0, 0, 0.87)" }}>Fieldprint Result</Header>
                            </Grid>

                            <Grid item xs={12}>
                                <Paragraph>
                                    Fieldprint results are shown on the spidergram as relative indices on a scale of
                                    1-100 that represent your metric scores. The indices are calculated so that smaller
                                    values indicate less resource use or environmental impact from your field. This
                                    illustration can be used to identify where the greatest opportunities for
                                    improvement are for your field, and over time can be used to evaluate progress and
                                    trade-offs between different sustainability metrics for your field.
                                </Paragraph>
                            </Grid>

                            <Grid item xs={12}>
                                <SpiderGram
                                    cropYear={cropYear}
                                    showState={true}
                                    showNational={true}
                                    showProject={getValue(cropYear, "project.project_benchmarks")}
                                    showLegend={false}
                                    cx={360}
                                    cy={180}
                                    outerRadius={135}
                                    width={720}
                                    height={360}
                                />
                            </Grid>
                            <Grid item className={classes.spiderGramPadding} xs={12}>
                                <SpiderGramTable cropYear={cropYear} printable />
                            </Grid>
                            <Grid item xs={12}>
                                <Caption>
                                    The Water Quality score here indicates how many of the four loss pathways have been
                                    mitigated. For more detail on each metric score please refer to the individual
                                    metric output. Benchmarks represent an average based on USDA statistical data for
                                    the period 2008-2012 and provide context for how your scores relate to this known
                                    point. Benchmarks should not be interpreted as a specific level of sustainability,
                                    or a performance target. State and National benchmarks that are not shown in the
                                    table or on the spidergram are not available for the applicable metric.
                                    {getValue(cropYear, "project.project_benchmarks") &&
                                        " Project benchmarks represent the average performance across fields enrolled in the " +
                                            getValue(cropYear, "project.project_name") +
                                            " Project."}
                                </Caption>
                            </Grid>
                        </Grid>
                    </div>

                    <PageFooter page={1} pages={pageCount} />
                </div>
            </>
        );
    }
}

SummarySection = withStyles(styles)(SummarySection);

class MetricSection extends Component {
    render() {
        const { cropYear, classes, pageCount } = this.props,
            pages = [];
        let page,
            column = 0,
            lastHasBreak = false;
        getMetrics(cropYear, true).forEach(metric => {
            const hasBreak = getBreakBefore(metric);

            if (page && (page.columns[column].metrics.length >= 4 || hasBreak || lastHasBreak)) {
                column += 1;
            }

            if (!page || column >= 2) {
                column = 0;
                page = {
                    number: pages.length + 1 + 1,
                    columns: [
                        { number: 1, metrics: [] },
                        { number: 2, metrics: [] }
                    ]
                };
                pages.push(page);
            }

            page.columns[column].metrics.push(metric);

            lastHasBreak = getBreakAfter(metric);
        });

        return (
            <>
                {pages.map(({ number, columns }) => (
                    <div className={classes.page} key={number}>
                        <PageHeader cropYear={cropYear} />
                        <div className={classes.content}>
                            {columns.map(({ number, metrics }) => (
                                <div key={number} className={classNames(classes.column, classes["column" + number])}>
                                    {metrics.map(metric => (
                                        <div key={metric} className={classes.metric}>
                                            <MetricDetail metric={metric} mode={"all"} cropYear={cropYear} printable />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <PageFooter page={number} pages={pageCount} />
                    </div>
                ))}
            </>
        );
    }
}

MetricSection = withStyles(styles)(MetricSection);

export default function ReportLayout({ cropYear }) {
    const resultPages = 3,
        pageCount = cropYear.resultOnly ? resultPages : getPageRows(cropYear).length + resultPages;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider
                theme={outerTheme => ({ ...outerTheme, overrides: { ...outerTheme.overrides, ...themeOverrides } })}>
                <SummarySection cropYear={cropYear} pageCount={pageCount} />
                <MetricSection cropYear={cropYear} pageCount={pageCount} />
                {!cropYear.resultOnly && <InputDetail cropYear={cropYear} />}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
