import React from "react";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: "relative"
    },
    optionContainer: {
        display: "flex"
        // paddingBottom: "16px"
    },
    suggestion: {
        display: "block"
    },
    option: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    optionLabel: {
        marginRight: "10px",
        marginTop: "8px"
    },
    optionLabelError: {
        marginRight: "10px",
        marginTop: "8px",
        color: "red"
    },
    root: {
        backgroundColor: theme.palette.primary.main
    },
    rootText: {
        color: "#fff"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    gutterTop: {
        marginTop: ".6em"
    },
    noPadding: {
        paddingBottom: 0 + " !important"
    },
    minHeight: {
        // minHeight: "80vh",
        minWidth: 600
    },
    // dialogHeight: {
    //     height: 600
    // },
    expansionResults: {
        minHeight: 250,
        maxHeight: 250,
        width: "100%",
        overflowY: "auto"
    }
});

class cmzOptionsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cmzOptions: [],
            selectedCMZs: []
        };
    }

    filterCMZs = event => {
        let cmzOptions = this.state.cmzOptions;
        let compareValues = event.target.value.trim().split(" ");

        if (compareValues.length === 0) {
            cmzOptions.forEach(option => {
                option["show"] = true;
            });
            this.setState({ cmzOptions: cmzOptions });
        } else {
            cmzOptions.forEach(option => {
                let found = true;
                compareValues.forEach(compareValue => {
                    if (option.name.toLowerCase().includes(compareValue) === false) {
                        found = false;
                    }
                });
                option["show"] = found;
            });
            this.setState({ cmzOptions: cmzOptions });
        }
    };

    handleCMZSelectChange = event => {
        let selectedCMZIds = this.state.selectedCMZs;
        let index = selectedCMZIds.indexOf(event.target.value);

        if (event.target.checked === true) {
            if (index === -1) {
                selectedCMZIds.push(event.target.value);
            }
        } else {
            selectedCMZIds = selectedCMZIds.filter(cmzId => {
                return cmzId !== event.target.value;
            });
        }

        this.setState({ selectedCMZs: selectedCMZIds });
    };

    componentDidMount() {
        this.setState({
            cmzOptions: this.props.cmzOptions,
            selectedCMZs: this.props.alreadySelectedCMZs
        });
    }

    render() {
        const { classes } = this.props;

        const cmzsToShow = this.state.cmzOptions.filter(cmz => {
            return cmz["show"];
        });

        return (
            <Dialog open={this.props.open} onClose={this.handleClose} classes={{ paper: classes.minHeight }}>
                <DialogTitle className={classes.root}>
                    <Typography variant="title" className={classes.rootText}>
                        Select CMZs
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogHeight}>
                    <Form dontValidateOnMount="true" validateOnSubmit="true">
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Typography className={classes.gutterTop}>
                                    Select your CMZ to limit the template search results.
                                </Typography>

                                <TextField
                                    id="cmz-search"
                                    label="Find CMZs"
                                    type="search"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="filled"
                                    onChange={this.filterCMZs}
                                />
                                <div style={{ "max-height": "250px", "min-height": "250px", "overflow-y": "auto" }}>
                                    {cmzsToShow.map(option =>
                                        option["show"] === true ? (
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disableRipple={true}
                                                            checked={
                                                                this.state.selectedCMZs.filter(cmz => {
                                                                    return cmz === option.id;
                                                                }).length > 0
                                                            }
                                                            onChange={this.handleCMZSelectChange}
                                                            value={option.id}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={option.name}
                                                />
                                            </Grid>
                                        ) : (
                                            <div />
                                        )
                                    )}
                                    {cmzsToShow.length === 0 && (
                                        <Grid item xs={12}>
                                            No CMZs found.
                                        </Grid>
                                    )}
                                </div>
                            </form>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.props.onCancelClick()}
                        color="primary"
                        className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.props.onSaveClick(this.state.selectedCMZs)}
                        className={classes.buttonMargin}
                        variant="raised"
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(cmzOptionsDialog);
