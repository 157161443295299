import React from "react";

const AddNewIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 21 21">
        <circle
            cx="10.5"
            cy="10.5"
            r="10"
            fill="none"
            stroke="#969696"
            stroke-miterlimit="10"
            stroke-dasharray="3.1408 3.1408"
        />
        <line x1="10.5" y1="5.7" x2="10.5" y2="15.3" fill="none" stroke="#969696" stroke-miterlimit="10" />
        <line x1="5.7" y1="10.5" x2="15.3" y2="10.5" fill="none" stroke="#969696" stroke-miterlimit="10" />
    </svg>
);

export default AddNewIcon;
