import React from "react";

const CropRotationIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 25.8 31.4">
        <path
            d="M0.5,0.5H20.6141A4.6859,4.6859,0,0,1,25.3,5.1859V26.2141A4.6859,4.6859,0,0,1,20.6141,30.9H0.5a0,0,0,0,1,0,0V0.5A0,0,0,0,1,.5.5Z"
            fill="none"
            stroke="#7ac143"
            stroke-miterlimit="10"
        />
        <line x1="19.7" y1="0.5" x2="19.7" y2="30.9" fill="none" stroke="#7ac143" stroke-miterlimit="10" />
        <polygon
            points="7.7 7.7 5.7 6.1 3.7 7.7 3.7 0.5 7.7 0.5 7.7 7.7"
            fill="none"
            stroke="#7ac143"
            stroke-miterlimit="10"
        />
        <polyline
            points="12.767 16.266 15.988 13.045 12.767 9.821"
            fill="none"
            stroke="#7ac143"
            stroke-miterlimit="10"
        />
        <polyline points="15.985 13.029 5.298 13.029 5.298 17.86" fill="none" stroke="#7ac143" stroke-miterlimit="10" />
        <polyline points="7.516 19.889 4.295 23.11 7.516 26.335" fill="none" stroke="#7ac143" stroke-miterlimit="10" />
        <polyline
            points="4.303 23.094 14.991 23.094 14.991 18.263"
            fill="none"
            stroke="#7ac143"
            stroke-miterlimit="10"
        />
    </svg>
);

export default CropRotationIcon;
