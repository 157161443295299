import React from "react";

const WelcomeIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 29.04 27.8">
        <path
            fill="none"
            stroke="#ffb93c"
            d="M16,21.67H5.52a5,5,0,0,1-5-5V5.52a5,5,0,0,1,5-5h18a5,5,0,0,1,5,5V16.67a5,5,0,0,1-5,5H21.58v5.61Z"
        />
    </svg>
);

export default WelcomeIcon;
