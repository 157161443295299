import React from "react";

const NitrousIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 83.27 83.27">
        <path
            fill="#0096ff"
            d="M22.23 32.45h5.06l4.14 8.34 1.78 4.2h.12a67.06 67.06 0 01-.63-7.13v-5.41h4.71v18.7h-5.06l-4.14-8.38-1.78-4.17h-.12a70.2 70.2 0 01.63 7.14v5.41h-4.71zM38.95 54.29c2.51-2.32 4.46-4.1 4.46-5.54a1.24 1.24 0 00-1.31-1.41 2.63 2.63 0 00-1.74 1l-1.5-1.53a4.57 4.57 0 013.68-1.71 3.39 3.39 0 013.66 3.49c0 1.71-1.61 3.58-3.05 5.06a16.33 16.33 0 011.78-.14h1.77v2.42h-7.71zM46.38 41.71c0-6.15 3.51-9.61 8.69-9.61s8.69 3.49 8.69 9.61-3.51 9.78-8.69 9.78-8.69-3.62-8.69-9.78zm12.31 0c0-3.39-1.38-5.35-3.62-5.35s-3.62 2-3.62 5.35 1.41 5.5 3.62 5.5 3.62-2.11 3.62-5.5z"
        />
        <path
            fill="#0096ff"
            d="M41.64 0a41.64 41.64 0 1041.64 41.64A41.67 41.67 0 0041.64 0zm0 75.65a34 34 0 1134-34 34 34 0 01-34 34z"
        />
    </svg>
);

export default NitrousIcon;
