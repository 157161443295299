import LandUseIcon from "./img/land_use.png";
import SoilConservationIcon from "./img/soil_conservation.png";
import SoilCarbonIcon from "./img/soil_carbon.png";
import IrrigationWaterIcon from "./img/irrigation_water.png";
import EnergyUseIcon from "./img/energy_use.png";
import GreenhouseGasIcon from "./img/greenhouse_gas.png";
import WaterQualityIcon from "./img/water_quality.png";
import BiodiversityIcon from "./img/biodiversity.png";
import BenchmarkToggleProject from "./img/benchmark-toggle-project.png";
import BenchmarkToggleState from "./img/benchmark-toggle-state.png";
import BenchmarkToggleNational from "./img/benchmark-toggle-national.png";

const ICONS = {
    landUse: LandUseIcon,
    soilConservation: SoilConservationIcon,
    soilCarbon: SoilCarbonIcon,
    irrigationWaterUse: IrrigationWaterIcon,
    energyUse: EnergyUseIcon,
    greenhouseGas: GreenhouseGasIcon,
    waterQuality: WaterQualityIcon,
    biodiversity: BiodiversityIcon,
    soilConservationInsights: SoilConservationIcon,
    project: BenchmarkToggleProject,
    state: BenchmarkToggleState,
    national: BenchmarkToggleNational
};

export function getIcon(code) {
    return ICONS[code];
}
