import React from "react";
import Button from "@mui/material/Button";

const VariantMap = {
    raised: "contained",
    text: "text",
    outlined: "outlined",
    contained: "contained",
    fab: "text",
    extendedFab: "text",
    flat: "text"
};

const ButtonWrapper = React.forwardRef((props, ref) => {
    const { color, variant, ...rest } = props;

    var newColor = "grey";
    var newVariant = "text";

    if (variant && variant in VariantMap) {
        newVariant = VariantMap[variant];
    }

    if (color === false || color === null || color === undefined) {
        if (newVariant === "text") {
            newColor = "greyText";
        } else {
            newColor = "grey";
        }
    } else {
        newColor = color;
    }

    return <Button variant={newVariant} color={newColor} {...rest} ref={ref} />;
});

export default ButtonWrapper;
