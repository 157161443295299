import React from "react";

const FieldLibraryIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 25.8 31.4">
        <path
            d="M35.3,179.8951a10.7707,10.7707,0,0,1,10.7707,10.7707"
            transform="translate(-32.1 -166.8)"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <path
            d="M35.3,183.0927a7.5732,7.5732,0,0,1,7.5732,7.5732"
            transform="translate(-32.1 -166.8)"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <path
            d="M35.3,186.3491a4.3167,4.3167,0,0,1,4.3167,4.3167"
            transform="translate(-32.1 -166.8)"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <path
            d="M42.2,182.3952a10.7272,10.7272,0,0,1,6.9-2.5"
            transform="translate(-32.1 -166.8)"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <path
            d="M44.326,184.7866a7.5417,7.5417,0,0,1,4.774-1.6939"
            transform="translate(-32.1 -166.8)"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <path
            d="M45.7511,187.9418A4.3083,4.3083,0,0,1,49.1,186.3491"
            transform="translate(-32.1 -166.8)"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <path
            d="M0.5,0.5H20.6141A4.6859,4.6859,0,0,1,25.3,5.1859V26.2141A4.6859,4.6859,0,0,1,20.6141,30.9H0.5a0,0,0,0,1,0,0V0.5A0,0,0,0,1,.5.5Z"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
        <line x1="19.7" y1="0.5" x2="19.7" y2="30.9" fill="none" stroke="#34a853" stroke-miterlimit="10" />
        <polygon
            points="7.7 7.7 5.7 6.1 3.7 7.7 3.7 0.5 7.7 0.5 7.7 7.7"
            fill="none"
            stroke="#34a853"
            stroke-miterlimit="10"
        />
    </svg>
);

export default FieldLibraryIcon;
