import React from "react";

const StepThreeIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 147 144">
        <circle cx="75" cy="72" r="72" fill="#f2f2f2" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M23.05 32.13H128.06V112H23.05z" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M39.48 94.33L39.48 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.2 96.11L30.2 94.33" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M58.04 94.33L58.04 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M48.76 94.33L48.76 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M76.6 94.33L76.6 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M67.32 94.33L67.32 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M95.16 94.33L95.16 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M85.88 94.33L85.88 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M113.72 94.33L113.72 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M104.44 94.33L104.44 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M123 94.33L123 96.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 68.31L28.26 68.31" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 63.07L28.26 63.07" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 78.77L28.26 78.77" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 73.54L28.26 73.54" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 89.23L28.26 89.23" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 84L28.26 84" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.11 94.46L28.26 94.46" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M30.2 94.37L122.92 94.37" />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M30.2 94.22L39.48 86.97 48.76 86.97 58.04 79.16 85.88 79.16 95.16 79.16 104 66.86 113.22 61.91 123 61.91 123 94.27"
            opacity="0.5"
        />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M30.2 94.22L39.48 89.35 48.76 89.35 58.04 84.4 95.16 84.18 104 74.22 113.22 71.63 123 71.63 123 94.27"
            opacity="0.65"
        />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M30.2 94.22L39.48 91.98 48.76 91.98 58.04 89.16 95.16 89.16 104 86.78 113.22 84.18 123 84.18 123 94.27"
        />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M108.29 102.26H122.97V106.37H108.29z" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M69.95 102.26H84.63V106.37H69.95z" opacity="0.65" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M53.27 104.32L67.06 104.32" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M101.08 41.9L110.55 41.9" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M91.41 104.32L105.21 104.32" />
        <rect
            width="24.88"
            height="9.74"
            x="98.18"
            y="37.11"
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            rx="1.3"
        />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M120.08 41.11L118.62 42.58 117.16 41.11" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M28.26 37.65H42.06V42.01H28.26z" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M28.26 46.38L88.08 46.38" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M28.26 50.74L88.08 50.74" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M28.26 55.1L72.91 55.1" />
        <circle cx="18.5" cy="19.5" r="18.5" fill="#a368ff" />
        <path
            fill="#fff"
            d="M13.97 23.69a5.17 5.17 0 003.74 1.73c1.71 0 2.9-.89 2.9-2.38 0-1.64-1-2.68-4.63-2.68v-2.08c3.1 0 4.11-1.07 4.11-2.53a2.06 2.06 0 00-2.33-2.11 4.61 4.61 0 00-3.17 1.49l-1.46-1.76a7 7 0 014.73-2c3 0 5.11 1.48 5.11 4.16a3.79 3.79 0 01-2.75 3.62v.1a4 4 0 013.27 3.92c0 2.88-2.5 4.56-5.53 4.56a6.88 6.88 0 01-5.36-2.23z"
        />
    </svg>
);

export default StepThreeIcon;
