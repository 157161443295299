import React, { Component } from "react";
import AppContainer from "../common/AppContainer";
import withStyles from "@mui/styles/withStyles";
import Button from "../common/ButtonWrapper";
import PrintIcon from "@mui/icons-material/Print";
import { Project } from "./models";
import { connect } from "react-redux";
import { Table, TableRow, TableCell } from "@mui/material";
import ReactToPrint from "react-to-print";
import ReportLayout from "./ReportLayout";

import { Link } from "react-router-dom";

const getProject = Project.selectByUrlId();

// Custom CSS classes
const styles = theme => ({
    printHeader: {
        maxWidth: "8.5in"
    },
    printWarning: {
        fontSize: "1.5em",
        color: "red"
    }
});

class Report extends Component {
    render() {
        const { project, classes } = this.props;

        return (
            <AppContainer authenticated>
                {project && project.name !== "Not Found" && (
                    <div>
                        <Table paddingDense className={classes.printHeader}>
                            <TableRow>
                                <TableCell className={classes.printWarning} style={{ width: "2.5in", padding: "4px" }}>
                                    Note: For optimal print layout, use the "Print Report" button (at right) rather than
                                    the system Print command.
                                </TableCell>
                                <TableCell style={{ width: "1in", padding: "4px" }}>
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button variant="raised" color="primary">
                                                Print Report &nbsp;
                                                <PrintIcon />
                                            </Button>
                                        )}
                                        title={"asdfasdfasdf"}
                                        content={() => this.componentRef}
                                    />
                                </TableCell>
                                <TableCell style={{ width: "1.5in", padding: "4px" }}>
                                    <Button
                                        variant="raised"
                                        color="primary"
                                        component={Link}
                                        to={"/projectadmin/" + project.id}>
                                        Back to Projects
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </Table>

                        <div ref={el => (this.componentRef = el)}>
                            <ReportLayout project={project} />
                        </div>
                    </div>
                )}
            </AppContainer>
        );
    }
}

Report = connect((state, ownProps) => ({
    project: getProject(state, ownProps)
}))(Report);

export default withStyles(styles)(Report);
