import React from "react";

const FieldprintProjectIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 32.8675 32.0685">
        <polygon
            points="16.434 0.5 3.656 6.654 0.5 20.48 9.343 31.569 23.525 31.569 32.368 20.48 29.212 6.654 16.434 0.5"
            fill="none"
            stroke="#ff7d32"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <polygon
            points="16.434 5.825 7.763 10.001 5.622 19.383 11.622 26.907 21.245 26.907 27.245 19.383 25.104 10.001 16.434 5.825"
            fill="none"
            stroke="#ff7d32"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <polygon
            points="16.434 10.849 11.747 13.106 10.59 18.177 13.833 22.244 19.035 22.244 22.278 18.177 21.12 13.106 16.434 10.849"
            fill="none"
            stroke="#ff7d32"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);

export default FieldprintProjectIcon;
