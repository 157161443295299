import orm, { Model } from "../common/orm";

export class Delegation extends Model {
    static get fields() {
        return {
            //users : many("User")
        };
    }
    static get modelName() {
        return "Delegation";
    }
}
orm.register(Delegation);
