import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTour } from "@reactour/tour";

const styles = theme => ({
    flex: {
        flex: 1
    },
    contentPadding: {
        padding: "24px"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    linkColor: {
        color: "rgb(255, 125, 50)",
        cursor: "pointer",
        textDecoration: "underline"
    }
});

function HelpDialog({ open, onClose: handleClose, classes, authSetFlag, authState }) {
    const { pending, error } = authState.update || {},
        { setIsOpen, setCurrentStep } = useTour(),
        startTour = () => {
            setCurrentStep(0);
            setIsOpen(true);
            handleClose();
        },
        handleFlag = evt => authSetFlag({ newFieldMap: evt.target.checked ? "read" : null });
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <Toolbar>
                <Typography variant="title" className={classes.flex}>
                    Welcome to the new Field Boundary Editor!
                </Typography>
                <Tooltip title="Close">
                    <IconButton onClick={handleClose} size="large">
                        <Close color="primary" />
                    </IconButton>
                </Tooltip>
            </Toolbar>

            <DialogContent className={classes.contentPadding}>
                <Typography variant="body1" gutterBottom>
                    In response to user feedback, we made improvements to the page workflow and the tools available for
                    adding and editing your field boundary. This includes the addition of a selectable field boundary
                    library based on USDA CLU data and improved drawing tools. Click through this tour for a quick
                    overview of the new features. If you need any help, email us at{" "}
                    <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                        support@fieldtomarket.org
                    </a>
                    .
                </Typography>
            </DialogContent>

            <DialogActions className={classes.buttonMargin}>
                <div style={{ marginRight: "1em" }}>
                    {pending && <Typography color="secondary">Updating preference...</Typography>}
                    {error && <Typography color="error">{error}</Typography>}
                </div>
                <FormControlLabel
                    control={<Checkbox color="secondary" onChange={handleFlag} />}
                    label="Don't Show Again"
                />
                <Button onClick={handleClose} color="secondary" className={classes.cancelBorder}>
                    Close Help
                </Button>
                <Button onClick={startTour} color="primary" className={classes.cancelBorder}>
                    Start Tour
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withStyles(styles)(HelpDialog);
