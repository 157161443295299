import orm, { Model, ReferenceData } from "../common/orm";
import { fk } from "redux-orm";

export class Farm extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Farm";
    }
}
orm.register(Farm);

export class Field extends Model {
    static get fields() {
        return {
            farm: fk("Farm", "fields")
        };
    }
    static get modelName() {
        return "Field";
    }
    static get codeWidth() {
        return 4;
    }
}
orm.register(Field);

export class FarmCropYear extends Model {
    static get fields() {
        return {
            farm: fk("Farm", "farmcropyears"),
            crop: fk("Crops", "farmcropyears")
        };
    }
    static get modelName() {
        return "FarmCropYear";
    }
    static generateCode(payload) {
        if (!payload.crop || !payload.year) {
            throw new Error("Crop and year required");
        }
        return `${payload.year}-${payload.crop}`;
    }
    static generateId(payload) {
        if (!payload.crop || !payload.year) {
            throw new Error("Crop and year required");
        }
        return `${payload.year}-${payload.crop}`;
    }
}
orm.register(FarmCropYear);

export class FarmSurvey extends Model {
    static get fields() {
        return {
            farm: fk("Farm", "farmsurveys")
        };
    }
    static get modelName() {
        return "FarmSurvey";
    }
    static generateCode(payload) {
        if (!payload.farm || !payload.year) {
            throw new Error("Farm and year required");
        }
        return `${payload.farm}-${payload.year}`;
    }
    static generateId(payload) {
        if (!payload.farm || !payload.year) {
            throw new Error("Farm and year required");
        }
        return `${payload.farm}-${payload.year}`;
    }
}
orm.register(FarmSurvey);

export class FarmHpiSurvey extends Model {
    static get fields() {
        return {
            farm: fk("Farm", "farmhpisurveys")
        };
    }
    static get modelName() {
        return "FarmHpiSurvey";
    }
    static generateCode(payload) {
        if (!payload.farm || !payload.year) {
            throw new Error("Farm and year required");
        }
        return `${payload.farm}-${payload.year}`;
    }
    static generateId(payload) {
        if (!payload.farm || !payload.year) {
            throw new Error("Farm and year required");
        }
        return `${payload.farm}-${payload.year}`;
    }
}
orm.register(FarmHpiSurvey);

export class FarmHpiCultivatedLandQuestionnaire extends Model {
    static get fields() {
        return {
            survey: fk("FarmHpiSurvey", "cultivatedlandquestionnaire")
        };
    }
    static get modelName() {
        return "FarmHpiCultivatedLandQuestionnaire";
    }
}
orm.register(FarmHpiCultivatedLandQuestionnaire);

export class FarmHpiForestQuestionnaire extends Model {
    static get fields() {
        return {
            survey: fk("FarmHpiSurvey", "forestquestionnaire")
        };
    }
    static get modelName() {
        return "FarmHpiForestQuestionnaire";
    }
}
orm.register(FarmHpiForestQuestionnaire);

export class FarmHpiGrasslandQuestionnaire extends Model {
    static get fields() {
        return {
            survey: fk("FarmHpiSurvey", "grasslandquestionnaire")
        };
    }
    static get modelName() {
        return "FarmHpiGrasslandQuestionnaire";
    }
}
orm.register(FarmHpiGrasslandQuestionnaire);

export class FarmHpiRiparianQuestionnaire extends Model {
    static get fields() {
        return {
            survey: fk("FarmHpiSurvey", "riparianquestionnaire")
        };
    }
    static get modelName() {
        return "FarmHpiRiparianQuestionnaire";
    }
}
orm.register(FarmHpiRiparianQuestionnaire);

export class FarmHpiSurfaceWaterQuestionnaire extends Model {
    static get fields() {
        return {
            survey: fk("FarmHpiSurvey", "surfacewaterquestionnaire")
        };
    }
    static get modelName() {
        return "FarmHpiSurfaceWaterQuestionnaire";
    }
}
orm.register(FarmHpiSurfaceWaterQuestionnaire);

export class FarmHpiWetlandQuestionnaire extends Model {
    static get fields() {
        return {
            survey: fk("FarmHpiSurvey", "wetlandquestionnaire")
        };
    }
    static get modelName() {
        return "FarmHpiWetlandQuestionnaire";
    }
}
orm.register(FarmHpiWetlandQuestionnaire);

export function SURVEY_COMPLETED(survey, boolean_only) {
    var data = {};

    if (survey.cultivated.length === 0) {
        data["cultivated"] = true;
    } else {
        survey.cultivated.forEach(q => {
            if (q.missing > 0) {
                data["cultivated"] = true;
            } else if (q.missing === 0 && q.required === 0) {
                data["cultivated"] = true;
            }
        });
    }

    if (survey.forest) {
        if (survey.forest.missing > 0) {
            data["forest"] = true;
        } else if (survey.forest.missing === 0 && survey.forest.required === 0) {
            data["forest"] = true;
        }
    }

    if (survey.grassland) {
        if (survey.grassland.missing > 0) {
            data["grassland"] = true;
        } else if (survey.grassland.missing === 0 && survey.grassland.required === 0) {
            data["grassland"] = true;
        }
    }

    if (survey.buffer) {
        if (survey.buffer.missing > 0) {
            data["buffer"] = true;
        } else if (survey.buffer.missing === 0 && survey.buffer.required === 0) {
            data["buffer"] = true;
        }
    }

    if (survey.surfacewater) {
        if (survey.surfacewater.missing > 0) {
            data["surface_water"] = true;
        } else if (survey.surfacewater.missing === 0 && survey.surfacewater.required === 0) {
            data["surface_water"] = true;
        }
    }

    if (survey.wetland) {
        if (survey.wetland.missing > 0) {
            data["wetland"] = true;
        } else if (survey.wetland.missing === 0 && survey.wetland.required === 0) {
            data["wetland"] = true;
        }
    }

    if (boolean_only) {
        if (Object.keys(data).length > 0) {
            return false;
        }
        return true;
    }
    return data;
}

export function SURVEY_STATUS(survey, index) {
    if (!survey) {
        return false;
    }
    if (index === 0) {
        return true;
    } else if (index === 1) {
        let temp = true;
        if (survey["cultivated"].length === 0) {
            temp = false;
        } else {
            survey["cultivated"].forEach(s => {
                if (s["completed"] === false) {
                    temp = false;
                }
            });
        }
        ["forest", "grassland", "buffer", "surfacewater", "wetland"].forEach(type => {
            if (survey[type]) {
                if (survey[type]["completed"] === false) {
                    temp = false;
                }
            }
        });
        return temp;
    } else if (index === 2) {
        let temp = true;
        if (survey["cultivated"].length === 0) {
            temp = false;
        } else {
            survey["cultivated"].forEach(s => {
                if (s["completed"] === false) {
                    temp = false;
                }
            });
        }
        ["forest", "grassland", "buffer", "surfacewater", "wetland"].forEach(type => {
            if (survey[type]) {
                if (survey[type]["completed"] === false) {
                    temp = false;
                }
            }
        });

        if (survey["is_final"] === false) {
            temp = false;
        }

        return temp;
    }
    return true;
}

export class SoilTextures extends ReferenceData {
    static get modelName() {
        return "SoilTextures";
    }
}
orm.register(SoilTextures);
