import React from "react";

const CarbonIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 83.27 83.27">
        <path
            fill="#34a853"
            d="M41.64 0a41.64 41.64 0 1041.63 41.64A41.67 41.67 0 0041.64 0zm0 75.65a34 34 0 1134-34 34 34 0 01-34 34z"
        />
        <path
            fill="#34a853"
            d="M23.56 31.36a8.2 8.2 0 015.7 2.47l-2.7 3.05a4.19 4.19 0 00-2.94-1.26c-2.24 0-4.14 2-4.14 5.41s1.61 5.46 4 5.46A4.35 4.35 0 0026.76 45l2.7 3a7.82 7.82 0 01-6 2.76c-4.89 0-9-3.16-9-9.55s4.27-9.85 9.1-9.85zM29.84 40.97c0-6.16 3.51-9.61 8.69-9.61s8.69 3.48 8.69 9.61-3.51 9.78-8.69 9.78-8.69-3.63-8.69-9.78zm12.31 0c0-3.39-1.38-5.35-3.62-5.35s-3.63 2-3.63 5.35 1.39 5.52 3.63 5.52 3.61-2.13 3.61-5.52zM48.62 53.54c2.51-2.31 4.46-4.09 4.46-5.53a1.24 1.24 0 00-1.31-1.41 2.61 2.61 0 00-1.75 1l-1.54-1.54a4.61 4.61 0 013.69-1.72 3.4 3.4 0 013.66 3.49c0 1.71-1.61 3.59-3.05 5.07a16.15 16.15 0 011.77-.14h1.78v2.45h-7.71zM64 35.68c4.26 0 6.27 3.1 6.27 7a8.67 8.67 0 01-.2 1.95h-8.19a3 3 0 003.33 2.42 6 6 0 002.94-.86l1.61 2.93a9.75 9.75 0 01-5.24 1.67c-4.2 0-7.42-2.76-7.42-7.54s3.4-7.57 6.9-7.57zm2.08 5.92c0-1.21-.47-2.18-2-2.18-1.09 0-2 .6-2.3 2.18z"
        />
    </svg>
);

export default CarbonIcon;
