import React, { Component } from "react";

import Typography from "../../common/TypographyWrapper";

import { getValue } from "../../../api/utils";
import { BENCHMARKS, BENCHMARKS_PROJECT, round, getLabel, getColor, getTableDescription } from "./StaticData";

import { withTableStyles, TH, TD } from "./Table";
import Callout from "./Callout";

// CSS in JS styles
const styles = {
    benchmarkSquareSVG: {
        height: "20px",
        width: "20px",
        margin: "10px",
        verticalAlign: "middle"
    }
};

class BenchmarkTable extends Component {
    render() {
        const { title, indicator, classes, printable, project_benchmarks } = this.props,
            { metric } = indicator;

        function getVal(path) {
            const value = getValue(indicator, path);
            if (value) {
                if (metric === "biodiversity") {
                    return round(value.value * 100, metric) + "% " + (value.unit || "");
                }
                return round(value.value, metric) + " " + (value.unit || "");
            }
        }

        const values = {
            active_year: getVal("fieldprintResult")
        };
        let hasBenchmark = false;

        var benchmarkList;
        if (project_benchmarks) benchmarkList = BENCHMARKS_PROJECT;
        else benchmarkList = BENCHMARKS;

        benchmarkList.forEach(benchmark => {
            if (benchmark === "project") {
                const bm = project_benchmarks.find(b => b.metric_label.includes(metric));
                if (bm) {
                    var units = bm.units === "dimensionless" ? "" : bm.units;
                    if (metric === "biodiversity") {
                        values[benchmark] = round(bm.value * 100, metric) + "% " + (units || "");
                    } else {
                        values[benchmark] = round(bm.value, metric) + " " + (units || "");
                    }
                    hasBenchmark = true;
                }
            } else {
                const value = getVal(`benchmarks.${benchmark}.fieldprintResult`);
                if (value) {
                    values[benchmark] = value;
                    hasBenchmark = true;
                }
            }
        });

        if (!hasBenchmark) {
            return null;
        }
        if (getValue(indicator, "benchmarks.scaleBasis") === "Neutral") {
            // Don't show benchmark table for SCI
            return null;
        }

        return (
            <Callout title={title} printable={printable} variant="triangle2" split={9}>
                <table className={classes.dataTable}>
                    <thead>
                        <tr>
                            <TH>Score</TH>
                            <TH>Result</TH>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(values).map(([code, value]) => (
                            <tr>
                                <TD first>
                                    <svg className={classes.benchmarkSquareSVG}>
                                        <rect width={20} height={20} fill={getColor(code)} />
                                    </svg>
                                    {getLabel(code)}
                                </TD>
                                <TD>{value}</TD>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Typography>{getTableDescription(metric)}</Typography>
            </Callout>
        );
    }
}

BenchmarkTable = withTableStyles(styles)(BenchmarkTable);

export default BenchmarkTable;
