import React from "react";
import Score from "./Score";
import { getValue } from "../../../../api/utils";

export default function WaterQualityScore({ indicator, printable }) {
    const result = getValue(indicator, "fieldprintResult"),
        value = result && result.value,
        indexName = result && result.index;

    if (indexName !== "STEP") {
        return null;
    }

    return (
        <Score
            value={
                <>
                    {value} <span style={{ color: "#999" }}>/ 4</span>
                </>
            }
            unit="Pathways Mitigated"
            printable={printable}
        />
    );
}
