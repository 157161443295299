import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import HorizontalLogo from "../img/logo_grey.png";

// Custom CSS classes
const styles = theme => ({
    header: {
        width: "8.5in"
    },
    headerImage: {
        height: "1in",
        backgroundColor: "#f15d22"
    },
    headerTitle: {
        display: "flex",
        color: "white",
        alignItems: "center",
        marginLeft: 16
    },
    year: {
        display: "inline-block",
        marginLeft: 4
    },
    flex: {
        display: "flex"
    },
    headerFont: {
        fontSize: 29,
        lineHeight: "1em"
    }
});

class PageHeader extends Component {
    render() {
        const { classes, project } = this.props;
        return (
            <div className={classNames(classes.header)}>
                <div className={classNames(classes.headerImage, classes.flex)}>
                    <img className={classes.headerImage} src={HorizontalLogo} alt={"Field To Market logo"} />
                    <div className={classes.headerTitle}>
                        <Typography variant="h6" className={classes.headerFont}>
                            {project.name}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(PageHeader);
