import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";

import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";

import AppContainer from "../common/AppContainer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { connect, useDispatch, useSelector } from "react-redux";
import * as navActions from "../common/actions";
import * as authActions from "../auth/actions";
import moment from "moment";

// CSS in JS styles
const styles = theme => ({
    box: {
        backgroundColor: "#e4e4e4",
        borderRadius: 8,
        padding: 16
    },
    title: {
        color: "#000"
    },
    title1: {
        color: "#000",
        fontSize: 22
    },
    h3: {
        color: "#000",
        fontSize: 20,
        fontFamily: "'Source Sans Pro', sans-serif",
        borderBottom: "0px"
    },
    linkColor: {
        color: theme.palette.primary.main
    }
});

const useStyles = makeStyles(styles);

function FormatDate(d) {
    return moment(d).format("MMMM D, YYYY");
}

class Research extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;
        return (
            <AppContainer authenticated color="research" title="Research Database">
                <Grid container spacing={24}>
                    <Grid item xs={12} xl={8}>
                        <Typography variant="title" className={classes.title1} gutterBottom>
                            To advance sustainable agriculture, Field to Market is implementing a new database for
                            scientific research. Through both the Fieldprint Calculator and participating{" "}
                            <a
                                className={classes.linkColor}
                                href="https://fieldtomarket.org/our-programs/fieldprint-platform/scaling-farmer-access/">
                                Qualified Data Management Partners
                            </a>
                            , users of the Fieldprint Platform will be able to opt in to share their data with approved
                            research projects.
                        </Typography>
                        <Typography gutterBottom>
                            <strong>
                                Opting in is a way to help make sure researchers are telling the real story of US
                                agriculture.
                            </strong>
                        </Typography>
                        <Typography gutterBottom>
                            We will only store your:
                            <ul>
                                <li>
                                    Data inputs, including what you entered about rotation, operations and management
                                </li>
                                <li>Connected soil profile and weather station information for each field</li>
                                <li>Fieldprint Platform metric scores</li>
                            </ul>
                        </Typography>
                        <Typography gutterBottom>
                            The database <strong>will not</strong> store your field boundaries, geographic coordinates,
                            username, field name or any other identifying information
                        </Typography>
                        <Typography gutterBottom>
                            Your data can only be accessed by researchers that:
                            <ul>
                                <li>
                                    Are doing research on a specific question related to sustainable agriculture, such
                                    as
                                    <ul>
                                        <li>Developing sustainability metrics</li>
                                        <li>Understanding conservation practice impact</li>
                                        <li>Developing decision support and practice guidelines for farmers</li>
                                        <li>Understanding sustainability trends and drivers</li>
                                    </ul>
                                </li>
                                <li>Are funded to do the work or in the process of being funded</li>
                                <li>
                                    <strong>Will not use the data for commercial use</strong>
                                </li>
                                <li>Commit to objective data use, communications and publications</li>
                                <li>
                                    Specify what crops and regions are of interest – only data specific to their
                                    research questions will be provided
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} xl={8}>
                        <ResearchOpt hideOnOpt />
                    </Grid>
                    <Grid item xs={12} xl={8}>
                        <Typography variant="title" className={classes.title1} gutterBottom>
                            FAQ
                        </Typography>

                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: Who will have access to my data?
                        </Typography>
                        <Typography gutterBottom>
                            A: Agriculture researchers with specific need of the data to address a relevant science
                            question
                        </Typography>

                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: Who decides which researchers access my data?
                        </Typography>
                        <Typography gutterBottom>
                            A: Field to Market’s Science Advisory Council, supported by staff, will review all proposals
                            and may approve or deny requests
                        </Typography>

                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: Who benefits from the research results?
                        </Typography>
                        <Typography gutterBottom>
                            A: Projects using the Research Database must make their findings public to support the
                            advancement of sustainable U.S. agriculture.
                        </Typography>

                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: What safeguards are in place for the research results?
                        </Typography>
                        <Typography gutterBottom>
                            A: Researchers must submit materials for review to Field to Market before publishing or
                            otherwise disseminating the results of the research that uses your data.
                        </Typography>

                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: How do I know who has used my data?
                        </Typography>
                        <Typography gutterBottom>
                            A: Field to Market will list approved research projects and publications from the Research
                            Database on their web site.
                        </Typography>

                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: Can I change my mind?
                        </Typography>
                        <Typography gutterBottom>
                            A: Yes! You can opt-out any time, but changes will only be effective as of next April 30.
                            For example, if you opt out on May 1, 2022 your data will be available until April 30, 2023.
                        </Typography>
                        <Typography variant="title" className={classes.title} gutterBottom>
                            Q: Can I see an example of the data researchers will receive?
                        </Typography>
                        <Typography gutterBottom>
                            A. Yes!{" "}
                            <a
                                className={classes.linkColor}
                                href="https://calculator.fieldtomarket.org/media/Field-to-Market-Research-Database-Example-Output.pdf">
                                Click here
                            </a>{" "}
                            to view an example of the data output research will receive if granted access.
                        </Typography>
                        <Typography gutterBottom style={{ marginTop: "2em" }}>
                            Do you have additional questions? Please contact us at{" "}
                            <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>
                            .
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Research = connect(
    state => ({
        authState: state.auth
    }),
    {
        ...navActions,
        ...authActions
    }
)(Research);

export default withStyles(styles)(Research);

export function ResearchOpt({ hideOnOpt }) {
    const classes = useStyles(),
        dispatch = useDispatch(),
        authState = useSelector(state => state.auth),
        { research_opt, last_opt_date } = authState.user || {},
        { pending, error } = authState.update || {},
        handleCheck = evt => {
            dispatch(
                authActions.authResearch("opt", {
                    research_opt: evt.target.checked,
                    research_hide: evt.target.checked && hideOnOpt
                })
            );
        };

    return (
        <div className={classes.box}>
            <Typography variant="subheading" className={classes.h3}>
                Opt-In Status
            </Typography>
            <Typography>
                {research_opt && <>You last opted in to the research database on {FormatDate(last_opt_date)}. </>}
                If you would like to opt {!research_opt ? "in, check" : "out, uncheck"} the box below.
            </Typography>
            <FormControlLabel
                control={<Checkbox color="secondary" checked={research_opt} onChange={handleCheck} />}
                label="Opt in to the Research Database"
            />
            {pending && <Typography color="secondary">Updating preference...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {!pending &&
                !error &&
                (research_opt ? (
                    <Typography color="secondary">
                        You are currently opted into the research database. Data is aggregated and anonymized and added
                        to the catalogue at the end of each year. You can opt out prior to April 30th.
                    </Typography>
                ) : (
                    <Typography color="primary">
                        You are <strong>not</strong> currently opted into the research database.
                    </Typography>
                ))}
        </div>
    );
}
