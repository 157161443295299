import React, { Component } from "react";
import classNames from "classnames";

import Button from "../../common/ButtonWrapper";
import { Table, TableBody, TableRow } from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RotationWrapper from "./RotationWrapper";
import Checkbox from "../../common/Checkbox";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 500
    },
    centerAlign: {
        textAlign: "center"
    },
    deleteWidth: {
        minWidth: "44px",
        width: "44px",
        marginLeft: "auto",
        marginRight: "auto"
    },
    noPadding: {
        padding: "0 !important"
    },
    bold: {
        fontWeight: 900
    },
    invalidText: {
        color: "red"
    }
});

const columnData = [
    { id: "use", numeric: false, label: "Use Template", center: true },
    { id: "name", numeric: false, label: "Template Name", allowSort: true },
    {
        id: "template_name",
        numeric: false,
        disablePadding: false,
        allowSort: true,
        label: "NRCS Template Name"
    },
    { id: "modify_date", numeric: false, label: "Last Updated", allowSort: true },
    { id: "actions", numeric: false, label: "View Template", center: true }
];

class RotationTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "name",
            rotationPreviewOpen: false
        };
        this.onPreviewCancelClick = this.onPreviewCancelClick.bind(this);
    }

    onPreviewCancelClick() {
        this.setState({ rotationPreviewOpen: false });
    }

    previewRotation(rotationId) {
        this.setState({ selectedRotation: rotationId });
        this.setState({ rotationPreviewOpen: true });
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.rotationSystems.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.rotationSystems.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    checkApplied = (checked, id) => {
        const { applyRotation } = this.props;
        if (checked) {
            applyRotation(id);
        }
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    render() {
        const { classes, rotationSystems, selectedCropRotation, rotationSubmit } = this.props;

        const { order, orderBy, rotationPreviewOpen, selectedRotation } = this.state;

        const availableRotationSystems = rotationSystems.filter(
            rotationSystem => rotationSystem.is_valid === true || rotationSystem.id === selectedCropRotation
        );
        const cellClass = (id, invalid) =>
            classNames(id === selectedCropRotation && classes.bold, invalid && classes.invalidText);

        return (
            <div className={classes.root}>
                <Table className={classes.table}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={columnData}
                    />
                    <TableBody>
                        {availableRotationSystems.map(n => {
                            return (
                                <TableRow hover key={n.id}>
                                    <CustomTableCell>
                                        <Checkbox
                                            checked={n.id === selectedCropRotation}
                                            color="secondary"
                                            eventHandle={checked => this.checkApplied(checked, n.id)}
                                            field={n.id}
                                            label=""
                                            align={classNames(classes.deleteWidth)}
                                        />
                                    </CustomTableCell>
                                    <CustomTableCell className={cellClass(n.id)}>{n.name || n.id}</CustomTableCell>
                                    <CustomTableCell className={cellClass(n.id)}>{n.template_name}</CustomTableCell>
                                    <CustomTableCell className={cellClass(n.id, !n.is_valid)}>
                                        {n.is_valid === true ? this.formatDate(n.modify_date) : "Invalid Rotation"}
                                    </CustomTableCell>
                                    <CustomTableCell className={classes.centerAlign}>
                                        <Button
                                            onClick={() => this.previewRotation(n.id)}
                                            className={classes.deleteWidth}>
                                            <VisibilityIcon color="primary" />
                                        </Button>
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {rotationSystems.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={5} className={classes.centerAlign}>
                                    Rotation Library Empty
                                </CustomTableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <CustomTableCell colSpan={5} className={classNames(classes.centerAlign, classes.noPadding)}>
                                <Button onClick={rotationSubmit} fullWidth variant="raised" color="primary">
                                    <AddCircleOutlineIcon />
                                    &nbsp;&nbsp;&nbsp;Add New System
                                </Button>
                            </CustomTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <RotationWrapper
                    rotationPreviewOpen={rotationPreviewOpen}
                    selectedRotation={selectedRotation}
                    onPreviewCancelClick={this.onPreviewCancelClick}
                />
            </div>
        );
    }
}

export default withStyles(styles)(RotationTable);
