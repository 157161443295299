import React, { Component } from "react";
import Typography from "../../../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import Grid from "../../../common/GridWrapper";
import MapContainer from "../../../common/MapContainer";
import PreviewRotation from "../../../rotation/PreviewRotation";

const styles = theme => ({
    tableHeading: {
        padding: 4,
        marginTop: 8
    },
    tableHeadingLevel_1: {
        backgroundColor: theme.palette.primary.main
    },
    tableHeadingLevel_2: {
        backgroundColor: theme.palette.secondary.main
    },
    tableHeadingLevel_3: {
        backgroundColor: theme.palette.primary.light
    },
    tableHeadingLevel_4: {
        backgroundColor: theme.palette.secondary.light
    },
    tableHeadingText: {
        color: "#fff"
    },
    row: {
        borderBottom: "1px dashed #ccc"
    },
    rowLabel: {
        // fontStyle: "italic"
        fontSize: 13
    },
    rowValue: {
        fontSize: 13
    },
    subItem: {
        paddingLeft: "20px"
    },
    leftIndent: {
        paddingLeft: "10px"
    },
    question: {
        paddingLeft: "4px"
    },
    topDataQuestion: {
        paddingLeft: "4px"
    },
    wrapper: {
        paddingTop: 8,
        paddingBottom: 8
    }
});

function MainHeader({ value }) {
    return (
        <Grid item xs={12}>
            <Typography variant="title">{value}</Typography>
        </Grid>
    );
}

class _Header extends Component {
    render() {
        const { value, level, classes } = this.props;
        return (
            <Grid item xs={12} className={classNames(classes.tableHeading, classes[`tableHeadingLevel_${level}`])}>
                <Typography
                    style={{ fontSize: 16, lineHeight: 1.2 }}
                    variant="title"
                    className={classes.tableHeadingText}>
                    {value}
                </Typography>
            </Grid>
        );
    }
}

const Header = withStyles(styles)(_Header);
const Header1 = props => <Header level={1} {...props} />;
const Header2 = props => <Header level={2} {...props} />;
const Header3 = props => <Header level={3} {...props} />;
const Header4 = props => <Header level={4} {...props} />;

class _Row extends Component {
    render() {
        const { value: item, classes } = this.props;
        let value = item.value;
        if (("" + value).match(/\n/)) {
            value = value.split(/\n/).map(row => [row, <br />]);
        }
        return (
            <>
                <Grid
                    item
                    xs={6}
                    className={classNames(classes.row, item.subitem ? classes.subItem : classes.question)}>
                    <Typography variant="body2" className={classes.rowLabel}>
                        {item.label}
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classNames(classes.row, classes.leftIndent)}>
                    <Typography variant="body2" className={classes.rowValue}>
                        {value}
                    </Typography>
                </Grid>
            </>
        );
    }
}

const Row = withStyles(styles)(_Row);

function _Map({ value, classes }) {
    return (
        <Grid xs={12} className={classes.wrapper}>
            <MapContainer field={value} readonly />
        </Grid>
    );
}

const Map = withStyles(styles)(_Map);

function _Rotation({ value, classes }) {
    return (
        <Grid xs={12} className={classes.wrapper}>
            <PreviewRotation rotationSystem={value} inline />
        </Grid>
    );
}
const Rotation = withStyles(styles)(_Rotation);

const rowTypes = {
    MainHeader,
    Header1,
    Header2,
    Header3,
    Header4,
    item: Row,
    Map,
    Rotation
};

export default function AutoRow(row) {
    const Component = rowTypes[row.type] || Row;
    return <Component value={row.value} />;
}
