import React, { Component } from "react";
import Grid from "../../../common/GridWrapper";
import Typography from "../../../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import { getValue } from "../../../../api/utils";
import { getColor, round } from "../StaticData";

const styles = {
    text2: {
        color: getColor("active_year")
    }
};

class BiodiversityScore extends Component {
    render() {
        const { indicator, classes } = this.props,
            hpi = round(getValue(indicator, "fieldprintResult.value") * 100, indicator.metric),
            totalScore = round(getValue(indicator, "cultivatedLandScore"), indicator.metric);

        return (
            <>
                <Grid xs={12}>
                    <Typography>Your Total % Realized HPI for your Cultivated Land</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="headline" className={classes.text2}>
                        {hpi}%
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography>Total HPI Score</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="headline" className={classes.text2}>
                        {totalScore}
                    </Typography>
                </Grid>
            </>
        );
    }
}

BiodiversityScore = withStyles(styles)(BiodiversityScore);

export default BiodiversityScore;
