import React from "react";

const StepOneIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 151.5 144">
        <circle cx="79.5" cy="72" r="72" fill="#f2f2f2" />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            strokeWidth="1.026"
            d="M112.38 74.06L128.4 83.37 80.02 111.5 31.63 83.37 47.08 74.39"
        />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M104.21 50.6L112.38 55.41 87.95 69.79 79.78 64.98"
        />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M112.33 55.38L120.51 60.2 96.08 74.58 87.91 69.76"
        />
        <path
            fill="none"
            stroke="#a368ff"
            strokeMiterlimit="10"
            d="M120.46 60.17L128.63 64.98 104.21 79.36 96.03 74.55"
        />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M55.35 79.36L47.18 74.55 71.6 60.17 79.78 64.98" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M47.23 74.58L39.05 69.76 63.48 55.38 71.65 60.2" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M39.1 69.79L30.92 64.98 55.35 50.6 63.53 55.41" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M55.35 50.6L63.53 45.79 87.95 60.17 79.78 64.98" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M63.48 45.81L71.65 41 96.08 55.38 87.91 60.2" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M71.6 41.03L79.78 36.22 104.21 50.6 96.03 55.41" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M104.21 79.36L96.03 84.17 71.6 69.79 79.78 64.98" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M96.08 84.14L87.91 88.96 63.48 74.58 71.65 69.76" />
        <path fill="none" stroke="#a368ff" strokeMiterlimit="10" d="M87.95 88.93L79.78 93.74 55.35 79.36 63.53 74.55" />
        <circle cx="18.5" cy="20" r="18.5" fill="#a368ff" />
        <path fill="#fff" d="M13.85 25.62h3.52V15.26h-2.9v-1.79a10.32 10.32 0 003.64-1.29h2.13v13.44h3.1v2.33h-9.49z" />
    </svg>
);

export default StepOneIcon;
