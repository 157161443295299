import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import Grid from "../common/GridWrapper";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RadioGroup from "../common/RadioGroup";
import classNames from "classnames";
import saveAs from "file-saver";
import { Form } from "react-form";
import { dbFetch } from "../../api/fetch";

const styles = theme => ({
    flex: {
        flex: 1
    }
});

function ExportFieldDialog({ open, geometry, onClose: handleClose, classes }) {
    const [message, setMessage] = useState("");

    async function handleSubmit({ format }) {
        if (format === "geojson") {
            exportGeojson(geometry);
            handleClose();
        } else if (format === "shp") {
            setMessage("Generating shapefile...");
            try {
                await exportShapefile(geometry);
            } catch (e) {
                setMessage("Error: " + e.message);
            }
        } else {
            setMessage("Unsupported format");
        }
    }

    function handleSubmitFailure() {
        setMessage("Error generating download");
    }
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <Form defaultValues={{ format: "shp" }} onSubmit={handleSubmit} onSubmitFailure={handleSubmitFailure}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Toolbar>
                            <Typography variant="title" className={classes.flex}>
                                Export Field Boundary
                            </Typography>
                            <Tooltip title="Close">
                                <IconButton onClick={handleClose} size="large">
                                    <Close color="primary" />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>

                        <DialogContent className={classes.contentPadding}>
                            <RadioGroup
                                field="format"
                                name="format"
                                fullWidth
                                label="Format"
                                options={[
                                    { value: "shp", label: "Shapefile" },
                                    { value: "geojson", label: "GeoJSON" }
                                ]}
                            />
                            {message && (
                                <Grid item xs={12}>
                                    <Typography>{message}</Typography>
                                </Grid>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="button"
                                onClick={handleClose}
                                color="primary"
                                className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                                Cancel
                            </Button>

                            <Button type="submit" className={classes.buttonMargin} variant="raised" color="primary">
                                Download File
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
}

function createBlob(geometry) {
    const text = JSON.stringify(geometry),
        blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    return blob;
}

function exportGeojson(geometry) {
    const blob = createBlob(geometry),
        filename = geometry.properties.name + ".geojson";
    saveAs(blob, filename);
}

async function exportShapefile(geometry) {
    const blob = createBlob(geometry),
        body = new FormData();
    body.append("file", blob, geometry.properties.name + ".geojson");

    const response = await dbFetch("/api/geojson2shp", {
        method: "POST",
        body,
        headers: {
            "content-type": "multipart/form-data"
        }
    });

    const data = await response.blob();

    saveAs(data, geometry.properties.name + ".zip");
}

export default withStyles(styles)(ExportFieldDialog);
