import React, { Component } from "react";
import Grid from "@mui/material/Grid";

class GridWrapper extends Component {
    render() {
        const { spacing, ...rest } = this.props;

        var newSpacing = 0;

        if (spacing !== null && spacing !== undefined) {
            newSpacing = Math.round(spacing / 8);
        }

        return <Grid spacing={newSpacing} {...rest} />;
    }
}

export default GridWrapper;
