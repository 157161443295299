import React from "react";

const SubquestionIcon = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46.5" height="59.5" {...rest} viewBox="0 0 59.5 46.5">
        <path
            d="M7357.5,5017.5v37.2422a8.7578,8.7578,0,0,0,8.7578,8.7578H7416.5"
            transform="translate(-7357 -5017.5)"
            fill="none"
            stroke="#b1b1b1"
            stroke-miterlimit="10"
            stroke-dasharray="4"
        />
    </svg>
);

export default SubquestionIcon;
