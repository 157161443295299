import React from "react";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: "relative"
    },
    optionContainer: {
        display: "flex"
        // paddingBottom: "16px"
    },
    suggestion: {
        display: "block"
    },
    option: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    optionLabel: {
        marginRight: "10px",
        marginTop: "8px"
    },
    optionLabelError: {
        marginRight: "10px",
        marginTop: "8px",
        color: "red"
    },
    root: {
        backgroundColor: theme.palette.primary.main
    },
    rootText: {
        color: "#fff"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    gutterTop: {
        marginTop: ".6em"
    },
    noPadding: {
        paddingBottom: 0 + " !important"
    },
    minHeight: {
        // minHeight: "80vh",
        minWidth: 600
    },
    // dialogHeight: {
    //     height: 600
    // },
    expansionResults: {
        minHeight: 250,
        maxHeight: 250,
        width: "100%",
        overflowY: "auto"
    }
});

class durationOptionsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            durationOptions: [],
            selectedDuration: ""
        };
    }

    handleDurationChange = event => {
        this.setState({ selectedDuration: event.target.value });
    };

    componentDidMount() {
        this.setState({
            durationOptions: this.props.durationOptions,
            selectedDuration: this.props.alreadySelectedDuration
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Dialog open={this.props.open} onClose={this.handleClose} classes={{ paper: classes.minHeight }}>
                <DialogTitle className={classes.root}>
                    <Typography variant="title" className={classes.rootText}>
                        Select Duration
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogHeight}>
                    <Form dontValidateOnMount="true" validateOnSubmit="true">
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.gutterTop}>
                                            Select the duration for the rotation system.
                                        </Typography>
                                    </Grid>

                                    <Grid item className={classes.expansionResults}>
                                        <RadioGroup
                                            value={this.state.selectedDuration}
                                            onChange={this.handleDurationChange}>
                                            {this.state.durationOptions.map(option => (
                                                <FormControlLabel
                                                    value={option.value}
                                                    control={<Radio color="primary" disableRipple={true} />}
                                                    label={option.label}
                                                    labelPlacement="start"
                                                />
                                            ))}
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.props.onCancelClick()}
                        color="primary"
                        className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.props.onSaveClick(this.state.selectedDuration)}
                        className={classes.buttonMargin}
                        variant="raised"
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(durationOptionsDialog);
