import { useRef } from "react";
export default function useGeolocate(onSuccess, onError) {
    const locRef = useRef(),
        start = () => {
            if (locRef.current) {
                return;
            }
            locRef.current = navigator.geolocation.watchPosition(
                evt => {
                    const { latitude, longitude } = evt.coords;
                    onSuccess({
                        latitude,
                        longitude,
                        zoom: 14
                    });
                },
                () => {
                    locRef.current = null;
                    onError();
                },
                { enableHighAccuracy: true }
            );
        },
        stop = () => {
            if (!locRef.current) {
                return;
            }
            navigator.geolocation.clearWatch(locRef.current);
            locRef.current = null;
        };
    return [start, stop];
}
