import React, { Component } from "react";
import { FormField } from "react-form";
import MuiCheckbox from "@mui/material/Checkbox";
import { FormControlLabel, FormHelperText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const styles = {
    error: {
        color: "#f44336"
    }
};

class CheckboxWrapper extends Component {
    handleChange = event => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        setValue(event.target.checked);

        if (eventHandle) eventHandle(event.target.checked, this);
    };

    render() {
        const { fieldApi, label, align, classes, color, ...rest } = this.props;

        const { getValue, getError, getWarning, setTouched } = fieldApi;

        const value = getValue() || false,
            error = getError(),
            warning = getWarning(),
            hasError = !!(error || warning);

        return (
            <div className={align}>
                <FormControlLabel
                    control={
                        <div>
                            <MuiCheckbox
                                color={color ? color : "primary"}
                                onChange={this.handleChange}
                                onBlur={() => setTouched()}
                                checked={value}
                                {...rest}
                            />
                        </div>
                    }
                    label={label || ""}
                    classes={{
                        label: hasError ? classes.error : ""
                    }}
                />
                {hasError && (
                    <FormHelperText className={align} error={true}>
                        {error}
                    </FormHelperText>
                )}
            </div>
        );
    }
}

const Checkbox = FormField(CheckboxWrapper);

export default withStyles(styles)(Checkbox);
